<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва" v-model="name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" filled label="Відсоток (%)" v-model="percent"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="percent ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" filled label="Код за ЄДАРП" v-model="edarp_code"
                          hide-details
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-select type="text" filled label="Бюджет" v-model="budget" :items="budget_select"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="percent ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {CREATE_PRIVILEGE_CATEGORIES, UPDATE_PRIVILEGE_CATEGORIES, REMOVE_PRIVILEGE_CATEGORIES} from "@/store/actions/privilege";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {budget_select} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'privilege_category_modal_delete'

export default {
  name: "HWP_Modal_PrivilegeCategory",
  mixins: [ModalComponentMixin],
  data() {
    return {
      formValid: false,
      name: this.item.name,
      percent: this.item.percent || 0,
      budget: this.item.budget || null,
      edarp_code: this.item.edarp_code,
      budget_select
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.name = this.item.name
      this.percent = this.item.percent || 0
      this.budget = this.item.budget || null
      this.edarp_code = this.item.edarp_code

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення категорії пільги ${this.name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Назва має бути заповнена',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        name: this.name,
        percent: this.percent,
        budget: this.budget,
        edarp_code: this.edarp_code
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_PRIVILEGE_CATEGORIES, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_PRIVILEGE_CATEGORIES, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.name = payload.name
              this.percent = payload.percent || 0
              this.budget = payload.budget || null
              this.edarp_code = payload.edarp_code
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_PRIVILEGE_CATEGORIES, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
}
</script>
